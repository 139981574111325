import React, { useState, useEffect } from "react";
import MenuComponent from "../components/MenuComponent";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation } from 'react-i18next';

export const About = () => {

  const [loaded, setLoaded] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { t, i18n } = useTranslation();

  const languageClass = i18n.language === 'gr' ? 'greek-font' : '';

    const handleCloseButton = () => {
      setIsMenuVisible(false);
    };

    const toggleMenu = () => {
      setIsMenuVisible(true);
    };

  
  useEffect(() => {
    const faders = document.querySelectorAll('.exhibitions-title');
    const appearOptions = {
      threshold: 0.5,
    };

    const appearOnScroll = new IntersectionObserver(function(entries, apperarOnScroll) {
    entries.forEach(entry => {
        if (!entry.isIntersecting) {
        return;
        } else {
        entry.target.classList.add("titles-appear");
        appearOnScroll.unobserve(entry.target);
        }
    });
    }, appearOptions);

    faders.forEach(fader => {
    appearOnScroll.observe(fader);
    }) 

    const textFaders = document.querySelectorAll('.excibitions-row');

    const textAppearOnScroll = new IntersectionObserver(function(entries, apperarOnScroll) {
    entries.forEach(entry => {
        if (!entry.isIntersecting) {
        return;
        } else {
        entry.target.classList.add("text-appear");
        textAppearOnScroll.unobserve(entry.target);
        }
    });
    }, appearOptions);

    textFaders.forEach(fader => {
      textAppearOnScroll.observe(fader);
    }) 

    const datelines1Element = document.getElementById("excibition-lines1");
    const datelines2Element = document.getElementById("excibition-lines2");
    const datelines3Element = document.getElementById("excibition-lines3");
    const datelines4Element = document.getElementById("excibition-lines4");
    const datelines5Element = document.getElementById("excibition-line-braker");

    const growOptions = {
        threshold: 0.5,
    };

    const datelines1Observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = "growLinesTotal 2s forwards 0.5s";
        }
        });
    }, growOptions);

    const datelines2Observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = "growLinesTotal 2s forwards 0.5s";
            datelines2Observer.unobserve(entry.target);        
        }
        });
    }, growOptions);

    const datelines3Observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = "growLinesTotal 2s forwards 0.5s";
            datelines3Observer.unobserve(entry.target);        
        }
        });
    }, growOptions);

    const datelines4Observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
      if (entry.isIntersecting) {
          entry.target.style.animation = "growLinesTotal 2s forwards 0.5s";
          datelines4Observer.unobserve(entry.target);     
      }
      });
    }, growOptions);

    const datelines5Observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
      if (entry.isIntersecting) {
          entry.target.style.animation = "growLinesTotal 2s forwards 0.5s";
          datelines5Observer.unobserve(entry.target);     
      }
      });
    }, growOptions);

    datelines1Observer.observe(datelines1Element);
    datelines2Observer.observe(datelines2Element);
    datelines3Observer.observe(datelines3Element);
    datelines4Observer.observe(datelines4Element);
    datelines5Observer.observe(datelines5Element);

  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1500);
  }, []);
  
  const imageStyle = {
    opacity: loaded ? 1 : 0,
    transform: loaded ? 'scale(1)' : 'scale(1.2)',
  };

  const titleStyle = {
    opacity: loaded ? 1 : 0,
    transform: loaded ? 'translateY(0%)' : 'translateY(150%)',
  };

  return (
    <>
    <Header toggleMenu={toggleMenu} />  
      <div className={`about-container ${languageClass}`}>
        <div className="main-bio-container">
          <div className="about-image-container">
              <div className="image-container">
              <img 
                style={imageStyle}
                className="about-image" 
                src="https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/Marios+AntoniadisProfile.jpg" 
                onLoad={() => setLoaded(true)}
              />
              </div>
            </div>
          <div className="biography-container">
            <div className="email-container">
              <button className="bio-email" 
                onLoad={() => setLoaded(true)}
                style={titleStyle}>mariosantoniadis@gmail.com</button>
            </div>
            <div className="infos-conainer">
              <div className="bio-title-container">
                <h2 className={`bio-title ${languageClass}`}
                  onLoad={() => setLoaded(true)}
                  style={titleStyle}>
                    {t("nameSmall")}
                </h2>
              </div>
              <div className="bio-title-container">
                <h3 className="bio-firstInfos"
                onLoad={() => setLoaded(true)}
                style={titleStyle}>{t("firstInfos")}</h3>
              </div>
            </div>
          </div>
        </div>
        <div id="excibition-line-braker"></div>
        <div className="about-exhibitions-container">
          <div className={`exhibitions ${languageClass}`}>
          <div className="exhibitions-title-container">
            <h1 className="exhibitions-title">{t("activity")}</h1>
          </div>
          <div className="exhibitions-container">
            <div className="excibitions-row">
              <div className="excibition-box-date">2022</div>
              <ul className="excibition-box-info">
                <li>Pulvis Umbra Collective </li>
                <li>Imagine Visual Arts Gallery «Re- Matter»</li>
                <li>Teesside University «Scripted Apes»</li>
                <li>Braggart Gallery, Joist Innovation Park</li>
                <li>Edessas Short Film Festival</li>
                <li>Metaphor Athens «Space Oddity»</li>
                <li>Municipal Art Gallery of Piraeus "-STIGMA"</li>
                <li>Ficthi Art 2022  «Pavillon abandonné»</li>
                <li>Archaeological Museum of Florina "Sustainable Cultural Heritage"</li>
              </ul>
            </div>
            <div className="excibitions-row">
              <div className="excibition-box-date">2020</div>
              <ul className="excibition-box-info">
                <li>Art Number 23 Gallery</li>
              </ul>
            </div>
            <div className="excibitions-row">
              <div className="excibition-box-date">2019</div>
              <ul className="excibition-box-info">
                <li>Fa.bri.ka Kollektiv Gallery «Utopia- Dystopia» (group exhibition)</li>
              </ul>
            </div>
          </div>
          <div id="excibition-lines1"></div>
          <div className="exhibitions-title-container">
            <h1 className="exhibitions-title">{t("programs")}</h1>
          </div>
          <div className="exhibitions-container">
            <div className="excibitions-row">
              <div className="excibition-box-date">2019</div>
              <ul className="excibition-box-info">
                <li>Rural 1.5 «Rotten Colors»</li>
              </ul>
            </div>
            <div className="excibitions-row">
              <div className="excibition-box-date">2019</div>
              <ul className="excibition-box-info">
                <li>A Glipse Into The Future</li>
              </ul>
            </div>
          </div>
          <div id="excibition-lines2"></div>
          <div className="exhibitions-title-container">
            <h1 className="exhibitions-title">{t("artFairs")}</h1>
          </div>
          <div className="exhibitions-container">
            <div className="excibitions-row">
              <div className="excibition-box-date">2022</div>
              <ul className="excibition-box-info">
                <li>Platforms Project 2022 «En-Flo art group»</li>
                <li>Art Thessaloniki 2022 «Pulvis Umbra»</li>
                <li>Art Thessaloniki 2022 «Imagine Gallery»</li>
              </ul>
            </div>
          </div>
          <div id="excibition-lines3"></div>
        </div>
        </div>
        <div className={`contact-container ${languageClass}`}>
          <h3 className="contact-infos exhibitions-title">{t("worksFind")} :</h3>
          <div className="">
            <li className="menu-buttons exhibitions-title">
              <a href="https://www.braggart.gr/artists/antoniadis-marios/" 
                target="_blank" rel="noopener noreferrer">braggart.gr</a>
            </li>
          </div>
        </div>
        <div id="excibition-lines4"></div>
        < Footer />
      </div>
      {isMenuVisible && (
        < MenuComponent closeButtonClicked={handleCloseButton} /> 
      )}
    </>
  );
};

export default About;
