import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
            name: "MARIOS ANTONIADIS",
            painter: "Artist",
            nameSmall: "Marios Antoniadis",
            gallery: "Gallery",
            about: "About",
            texts: "Schedule",
            close: "CLOSE",
            menu: "MENU",
            firstInfos: "BORN 1997, IN LARNACA, CYPRUS",
            activity: "EXHIBITION ACTIVITY",
            programs: "PROGRAMS",
            artFairs: "ART FAIRS",
            worksFind: "WORKS CAN BE FOUND IN",
            oil: "Oil on canvas",
            back: "back",
            upcoming: "Upcoming events",
            korcaDates: "25-28 October 2023",
            infosButton: "more information",
        },
      },
      gr: {
        translation: {
            name: "ΜΑΡΙΟΣ ΑΝΤΩΝΙΑΔΗΣ",
            painter: "Καλλιτέχνης",
            nameSmall: "Μάριος Αντωνιάδης",
            gallery: "Πινακοθήκη",
            about: "Βιογρφικό",
            texts: "Πρόγραμμα",
            close: "ΚΛΕΙΣΙΜΟ",
            menu: "ΜΕΝΟΥ",
            firstInfos: "Γεννημένος το 1997, στη Λάρνακα, Κύπρος",
            upcoming: "ΠΡΟΣΕΧΕΙΣ ΕΚΔΗΛΩΣΕΙΣ",
            activity: "ΕΚΘΕΣΙΑΚΗ ΔΡΑΣΤΗΡΙΟΤΗΤΑ",
            programs: "ΠΡΟΓΡΑΜΜΑΤΑ",
            artFairs: "ART FAIRS",
            worksFind: "ΤΑ ΕΡΓΑ ΜΠΟΡΟΥΝ ΝΑ ΒΡΕΘΟΥΝ ΣΤΗΝ",
            oil: "Λάδι σε καμβά",
            back: "πίσω",
            korcaDates: "25-28 Οκτωβρίου 2023",
            infosButton: "περισσότερες πληροφορίες",
        },
      },
    },
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;