import React, { useEffect, useRef } from "react";

function FrontPageAnimation({ divDimensions }) {
  const canvasRef = useRef(null);

  const videoPathsForLaptops = [ 
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+3.mp4", x: 80, y: 30},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+9.mp4", x: 75, y: 20},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+1.mp4", x: 5, y: 5},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+13.mp4", x: 8, y: 40},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+4.mp4", x: 60, y: 2},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+15.mp4", x: 50, y: 50},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+7.mp4", x: 75, y: 30},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+11.mp4", x: 5, y: 4},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+10.mp4", x: 40, y: 3},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+2.mp4", x: 50, y: 65},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+6.mp4", x: 0, y: 40},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+14.mp4", x: 75, y: 5},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+12.mp4", x: 20, y: 30},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+5.mp4", x: 20, y: 30},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+8.mp4", x: 40, y: 0},
  ];

  const videoPathsForPhones = [
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+3.mp4", x: 40, y: 30},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+4.mp4", x: 55, y: 2},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+1.mp4", x: 50, y: 70},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+9.mp4", x: 10, y: 5},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+13.mp4", x: 8, y: 50},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+15.mp4", x: 50, y: 50},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+7.mp4", x: 5, y: 5},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+11.mp4", x: 50, y: 4},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+10.mp4", x: 40, y: 70},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+2.mp4", x: 50, y: 50},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+6.mp4", x: 5, y: 40},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+14.mp4", x: 60, y: 5},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+12.mp4", x: 20, y: 20},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+5.mp4", x: 20, y: 30},
    {path: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/video+8.mp4", x: 40, y: 70},
  ];

  const videos = useRef([]);
  const currentVideoIndex = useRef(0);

  const videoPaths = window.innerWidth <= 900 ? videoPathsForPhones : videoPathsForLaptops;

  useEffect(() => {
    const canvas = canvasRef.current;
    let isUnmounted = false;

    const createVideo = (videoPath, x, y) => {
      const video = document.createElement("video");
      video.muted = true;
      
      video.style.position = "absolute";
      video.style.left = `${x}vw`;
      video.style.bottom = `${y}vh`;
      video.style.padding = 0
      video.style.margin = 0
      video.loop = false;
      video.autoplay = true;
      video.src = videoPath;
      
      setTimeout(() => {
        video.style.opacity = 1;
      }, 0);

      video.addEventListener("ended", () => {
        video.style.opacity = 0;
        setTimeout(() => {
          video.remove();
        }, 300);
      });

      canvas.parentNode.appendChild(video);
      videos.current.push(video);
    };

    const displayNextVideo = () => {
      if (isUnmounted) return;

      const nextVideoIndex = currentVideoIndex.current % videoPaths.length;
      const { path, x, y } = videoPaths[nextVideoIndex];
      createVideo(path, x, y);
    
      currentVideoIndex.current++;
      setTimeout(displayNextVideo, window.innerWidth <= 900 ? 5000 : 3500);
    };

    displayNextVideo();

    return () => {
      isUnmounted = true;
      videos.current.forEach((video) => {
        video.remove();
      });
    };
  }, [divDimensions]);

  return <canvas ref={canvasRef}></canvas>;
}

export default FrontPageAnimation;