import React, { useEffect, useRef, useState } from "react";
import  Component2022  from "../components/Component2022"; 
import MenuComponent from "./MenuComponent";
import Header from './Header';
import Footer from "./Footer"

const GalleryComponent = () => {

    const [loaded, setLoaded] = useState(false);

    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [fadeOutContent, setFadeOutContent] = useState(false);
    const [showContent, setShowContent] = useState(true);
    const [fadeInContent, setFadeInContent] = useState(false);
    const [showComponent2022, setShowComponent2022] = useState(false); 

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const handleCloseButton = () => {
      setIsMenuVisible(false);
    };

    const toggleMenu = () => {
      setIsMenuVisible(true);
    };

    useEffect(() => {
        setTimeout(() => {
          setLoaded(true);
        },1000);
      }, []);

      const titleStyle = {
        opacity: loaded ? 1 : 0,
        transform: loaded ? 'translateX(0%)' : 'translateY(-100%)',
      };


    const images2022 = [
        { year: 2022, id: 1, src: "../images/2022/karkinos.jpg", title: "Untitled", dimensions: "100 x 60" },
        { id: 2, src: "../images/2022/untitled2022- marios antoniadis.jpg", title: "Untitled", dimensions: "120 x 100" },
        { id: 3, src: "../images/2022/Untitled2022b.jpg", title: "Untitled 2", dimensions: "80 × 60" },
        { id: 4, src: "../images/2022/untitle.jpg", title: "Untitled", dimensions: "120 x 80" },
    ];

    const images2021 = [
        { year: 2021, id: 1, src: "../images/2021/3-Marios Antoniadis[843].jpg", title: "Untitled", dimensions: "80 x 60" },
        { id: 2, src: "../images/2021/untitled-eggtemperaonpaper.jpg", title: "Untitled", dimensions: "100 x 60" },
        { id: 3, src: "../images/2021/Untitled.jpg", title: "Untitled", dimensions: "60 x 50" },
        { id: 4, src: "../images/2021/Untitled.o.estavromenos.teliko.jpg", title: "Untitled", dimensions: "40 x 30" },
        { id: 5, src: "../images/2021/Untitled2.jpg", title: "Untitled", dimensions: "60 x 50" },
        { id: 6, src: "../images/2021/Untitledpsaraki.jpg", title: "Untitled", dimensions: "100 x 60" },
        { id: 7, src: "../images/2021/Untitled2021.jpg", title: "Untitled", dimensions: "100 x 60" },
        { id: 8, src: "../images/2021/Untitled2021b.jpg", title: "Untitled", dimensions: "60 x 40" },
        { id: 9, src: "../images/2021/untitled3.jpg", title: "Untitled", dimensions: "80 x 60" },
        { id: 10, src: "../images/2021/Untitled4.jpg", title: "Untitled", dimensions: "100 x 60" },
        { id: 11, src: "../images/2021/Untitled5.jpg", title: "Untitled", dimensions: "70 x 50" },
        { id: 12, src: "../images/2021/Untitled6.jpg", title: "Untitled", dimensions: "40 x 30" }
    ]

    const images2020 = [
        { year: 2020, id: 1, src: "../images/2020/third2020-removebg.png", title: "Unnamed", dimensions: "100 x 60" },
        { id: 2, src: "../images/2020/Untitled2020.jpg", title: "Image 2", dimensions: "..." },
        { id: 3, src: "../images/2020/Untitled7.jpg", title: "Image 3", dimensions: "..." },
    ];

    const handleBackButton = () => {
        setFadeInContent(false);
        setTimeout(() => {
          setShowContent(true);
          setFadeOutContent(false);
          setShowComponent2022(false);
        }, 300);
    };

    const handleImageSelect = (image, year) => {
      setSelectedImage(image);
      setShowComponent2022(true)
      setSelectedYear(year);
      setFadeOutContent(true)
      setTimeout(() => {
          setShowContent(false);
      }, 50);
      setFadeInContent(true);
  };

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        });
    };

    const year2022Ref = useRef(null);
    const year2021Ref = useRef(null);
    const year2020Ref = useRef(null);

    useEffect(() => {
        const faders = document.querySelectorAll('.fade-in');
        const appearOptions = {
        threshold: 0,
        rootMargin: "0px 0px -15px 0px"
        };

        const appearOnScroll = new IntersectionObserver(function(entries, apperarOnScroll) {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
            return;
            } else {
            entry.target.classList.add("appear");
            appearOnScroll.unobserve(entry.target);
            }
        });
        }, appearOptions);

        faders.forEach(fader => {
        appearOnScroll.observe(fader);
        });

        const datelines1Element = document.getElementById("datelines1");
        const datelines2Element = document.getElementById("datelines2");
        const datelines3Element = document.getElementById("datelines3");

        const growOptions = {
            threshold: 0.5,
        };

        const datelines1Observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.style.animation = "growLines 2s forwards 0.5s";
            }
            });
        }, growOptions);

        const datelines2Observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.style.animation = "growLines 2s forwards 0.5s";
                datelines2Observer.unobserve(entry.target);        
            }
            });
        }, growOptions);

        const datelines3Observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.style.animation = "growLines 2s forwards 0.5s";
                datelines3Observer.unobserve(entry.target);        
            }
            });
        }, growOptions);

        datelines1Observer.observe(datelines1Element);
        datelines2Observer.observe(datelines2Element);
        datelines3Observer.observe(datelines3Element);
  }, []);

  return (
    <>
    <div 
        className={`gallery-body ${showContent ? "show" : "hide"}`} 
        id={`${fadeOutContent ? "fadeContent" : "hide"}`} > 
        <Header  toggleMenu={toggleMenu} />  
      <div className="main-body-gallery">
        <div className="periods">
          <nav className="periodnav">
            <li onClick={() => scrollToRef(year2022Ref)}
                onLoad={() => setLoaded(true)}
                style={titleStyle} 
                className="period" 
                id="periods1">2022
            </li>
            <li onClick={() => scrollToRef(year2021Ref)}
                onLoad={() => setLoaded(true)}
                style={titleStyle}  
                className="period" 
                id="periods2">2021</li>
            <li onClick={() => scrollToRef(year2020Ref)} 
                onLoad={() => setLoaded(true)}
                style={titleStyle} 
                className="period" 
                id="periods3">2020</li>
          </nav>
        </div>
        <main className="container-paintings">
        <div id="paintingscontainer">
                <h1 className="dates" id="date1">2022</h1>
                <div ref={year2022Ref} id="datelines3"></div>
                <ul id="imagegrid">
                    {images2022.map((image) => (
                    <div
                        key={image.id}
                        id="imagecontainer"
                        className="fade-in"
                        onClick={() => handleImageSelect(image, images2022)}
                    >
                        <img src={image.src} alt={image.title} 
                        />
                        <div className="image_overlay">
                            <div className="image_title">{image.title}</div>
                            <div className="image_description">{image.dimensions}</div>
                        </div>
                    </div>
                    ))}
                </ul>
                <h1 ref={year2021Ref} className="dates" id="date2">2021</h1>
                <div id="datelines1"></div>
                <ul id="imagegrid2">
                {images2021.map((image) => (
                    <div
                        key={image.id}
                        id="imagecontainer"
                        className="fade-in"
                        onClick={() => handleImageSelect(image, images2021)}
                    >
                        <img src={image.src} alt={image.title} 
                        />
                        <div className="image_overlay">
                            <div className="image_title">{image.title}</div>
                            <div className="image_description">{image.dimensions}</div>
                        </div>
                    </div>
                ))}
                </ul>
                <h1 ref={year2020Ref} className="dates" id="date3">2020</h1>
                <div id="datelines2"></div>
                <ul id="imagegrid3">
                {images2020.map((image) => (
                    <div
                        key={image.id}
                        id="imagecontainer"
                        className="fade-in"
                        onClick={() => handleImageSelect(image, images2020)}
                    >
                        <img src={image.src} alt={image.title} 
                        />
                        <div className="image_overlay">
                            <div className="image_title">{image.title}</div>
                            <div className="image_description">{image.dimensions}</div>
                        </div>
                    </div>
                ))}
                </ul>
            </div>
            <div id="datelines4"></div>
        </main>
      </div>
    </div>
    {selectedImage && selectedYear && showComponent2022 && (
    <Component2022
    selectedImage={selectedImage}
    selectedYear={selectedYear}
    backButtonClicked={handleBackButton}
    />
    )}
    {isMenuVisible && (
    < MenuComponent closeButtonClicked={handleCloseButton} /> 
    )}
    {!showComponent2022 && (
    < Footer />
    )}
    </>
  );
};

export default GalleryComponent;