import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const MenuComponent = ({ closeButtonClicked }) => {

    const [loaded, setLoaded] = useState(false);
    const [menuOpen, setMenuOpen] = useState(true); 
    const { t, i18n } = useTranslation();

    const languageClass = i18n.language === 'gr' ? 'greek-font' : '';  

    useEffect(() => {
        setLoaded(true);
    }, []);

    const closeMenu = () => {
    setMenuOpen(false);
    };

    const titleStyle = {
        opacity: loaded ? 1 : 0,
        transform: loaded ? 'translateX(0%)' : 'translateY(-150%)',
      };
      
    const CloseButtonStyle = {
        opacity: loaded ? 1 : 0,
    };

    return (
        <>
        <div className={`menu-container ${languageClass}`}>
            <header className="menu-header">
                <button className="close-menu-button"
                style={CloseButtonStyle}
                onLoad={() => setLoaded(true)}
                onClick={() => {
                    setLoaded(false);
                    setTimeout(closeButtonClicked, 800);
                }}>{t("close")}</button>
            </header>
            <div className="main-menu-container">
                <div className="menu-division-container">
                    <div className="effect-container"
                    onLoad={() => setLoaded(true)}
                    style={titleStyle}>
                        <Link to="/gallery">
                            <li className="menu-buttons">{t("gallery")}</li>
                        </Link>
                    </div>
                    <div className="effect-container"
                    onLoad={() => setLoaded(true)}
                    style={titleStyle}>
                        <Link to="/about">
                            <li className="menu-buttons">{t("about")}</li>
                        </Link>
                    </div>
                    </div>
                    <div className="menu-name-container-2">
                        <Link to="/">
                            <h1 className="menu-name">{t("name")}</h1>
                        </Link>
                        <Link to="/">
                            <h1 className="menu-name-painter">{t("painter")}</h1>
                        </Link>
                    </div>
                    <div className="menu-division-container">
                    <div className="effect-container"
                    onLoad={() => setLoaded(true)}
                    style={titleStyle}>
                        <Link to="/schedule">
                            <li className="menu-buttons">{t("texts")}</li>
                        </Link>
                    </div>
                    <div className="effect-container"
                    onLoad={() => setLoaded(true)}
                    style={titleStyle}>
                        <li className="menu-buttons">
                            <a href="https://www.braggart.gr/artists/antoniadis-marios/"
                            style={{color: "#D0B2FF"}}
                            target="_blank" rel="noopener noreferrer">braggart.gr</a>
                        </li>
                    </div>
                </div>
            </div>
            <footer className="menu-footer">
                <a className="footer-websites" onLoad={() => setLoaded(true)}
                    style={titleStyle} href="https://www.instagram.com/marios_antoniadis_art/" 
                    target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
                <a className="footer-websites" onLoad={() => setLoaded(true)}
                    style={titleStyle} href="https://www.instagram.com/marios_antoniadis_art/" 
                    target="_blank" rel="noopener noreferrer">LEGAL NOTICE</a>
            </footer>
        </div>
        </>
    );
};

export default MenuComponent;