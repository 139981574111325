import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Link } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import './App.css';
import { Home } from "./pages/home";
import { Gallery } from "./pages/gallery"
import About from "./pages/about"
import Schedule from './pages/schedule';

function App() {
  const location = useLocation()

  return (
    <div className="App">
      <AnimatePresence model="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={ <Home/>} />
          <Route path="/gallery" element={ <Gallery/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/schedule" element={ <Schedule/>} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
