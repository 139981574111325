const Footer = ( { isHomePage } ) => {

    const footerClass = isHomePage ? 'home-footer' : '';

  return (
    <div className={`footer-container ${footerClass}`}>
        <span>© 2021 ALL RIGHTS RESERVED</span>
        <a href='#'>
            <span>Made ByMe</span>
        </a>
    </div>
  );
};

export default Footer;