import React, { useState, useEffect } from "react";
import FrontPageAnimation from '../components/AnimationComponent'
import MenuComponent from "../components/MenuComponent";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

export const Home = () => {

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [divDimensions, setDivDimensions] = useState({ top: 0, right: 0, width: 0, height: 0 });
  const [showContent, setShowContent] = useState(true);

  const { t, i18n } = useTranslation();
  const isHomePage = true;

  const languageClass = i18n.language === 'gr' ? 'greek-font' : '';  

  const handleCloseButton = () => {
    setIsMenuVisible(false);
    setShowContent(true);
  };

  const toggleMenu = () => {
    setIsMenuVisible(true);
    setTimeout(() => {
      setShowContent(false);
  }, 50);
  };

   const calculateDivDimensions = () => {
    const divElement = document.querySelector(".menu-name-container");
    if (divElement) {
      const rect = divElement.getBoundingClientRect();
      setDivDimensions({
        top: rect.top,
        right: rect.right,
        height: rect.height,
        width: rect.width
      });
    }
  };

  useEffect(() => {
    calculateDivDimensions();
    
    window.addEventListener("resize", calculateDivDimensions);

    return () => {
      window.removeEventListener("resize", calculateDivDimensions);
    };
  }, []);

  return (
      <div className={`home-container ${languageClass}`}>
        <Header toggleMenu={toggleMenu} /> 
        <div className="menu-name-container">
            <h1 className="menu-name">{t("name")}</h1>
            <h1 className="menu-name-painter">{t("painter")}</h1>
        </div>
        <Footer isHomePage={isHomePage}/> 
        {isMenuVisible && (
        < MenuComponent closeButtonClicked={handleCloseButton} /> 
        )}
        {showContent && (
        <FrontPageAnimation divDimensions={divDimensions} />
        )}
      </div>
  );
};

export default Home ;