import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

const Component2022 = ({ selectedYear, selectedImage, backButtonClicked }) => {

  const [initialRender, setInitialRender] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(
    selectedYear.findIndex((image) => image.id === selectedImage.id)
  );

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const minSwipeDistance = 50 

  const divisionCarouselRef = useRef(null);
  const { t } = useTranslation();
    
  useEffect(() => {
    if (backButtonClicked) {
        setCurrentIndex(0);
    }
  }, [backButtonClicked]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + selectedYear.length) % selectedYear.length);
    setInitialRender(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % selectedYear.length);
    setInitialRender(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      handlePrev();
    } else if (event.key === "ArrowRight") {
      handleNext();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const imagesToRender = [
    ...selectedYear.slice(selectedYear.findIndex(image => image.id === selectedImage.id)),
    ...selectedYear.slice(0, selectedYear.findIndex(image => image.id === selectedImage.id))
  ];  

  const onTouchStart = (e) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) {
      handleNext();
    } else if (isRightSwipe) {
      handlePrev();
    }
  }


  const divisionCarousel = document.getElementById("divisioncarousel1")

  function addFadeInAnimation(element) {
    element.style.animation = "fadeOut 1s ease-in-out forwards";
  }

  return (
    <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} ref={divisionCarouselRef} id="divisioncarousel1">
      <div id="carouselheading">
        <p id="year">{selectedYear[0].year}</p>
        <button id="buttonback" onClick={() => {
            addFadeInAnimation(divisionCarousel);
            setTimeout(backButtonClicked, 800);}}
            >{t("back")}</button>
      </div>
      <div id="images-container">
        <div id="buttons-container">
          <button onClick={handlePrev} id="left">&#8592;</button>
          <button onClick={handleNext} id="right">&#8594;</button>
        </div>
        {imagesToRender.map((image, index) => (
          <img
            key={image.id}
            id="image-carousel"
            src={image.src}
            alt={image.title}
            style={{
              opacity: initialRender ? (index === currentIndex ? 1 : 0) : (index === currentIndex ? 1 : 0),
              transition: initialRender ? "none" : "opacity 1.1s ease-in-out",
            }}
          />
        ))}
        <p id="numbering">{imagesToRender[currentIndex].id}/{imagesToRender.length}</p>
      </div>
      <p id="dimensions">
        {imagesToRender[currentIndex].title}, {t("oil")}, {imagesToRender[currentIndex].dimensions} cm
      </p>
    </div>
  );
};

export default Component2022;