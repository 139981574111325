import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = ({ toggleMenu }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const { t,i18n } = useTranslation(); 

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingUp = prevScrollPos > currentScrollPos;

      if (isScrollingUp) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
  };

  const headerStyle = {
    position: 'fixed',
    top: isVisible ? '0' : '-80px',
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 0.8s ease, top 0.7s ease',
    zIndex: 2,
  };

  return (
    <header className="header-main" style={headerStyle}>
          <ul className="header">
            <div className="menu-buttons-container">
              <button id="menu-button" onClick={toggleMenu}>{t("menu")}</button>
            </div>
            <Link to="/gallery">
              <li id="explore-button">
              <img style={{ height: "20px" }} src="../images/eye (1).svg" alt="" />
              </li>
            </Link>
            <li>
              <button 
              className="btn-language"
              onClick={() => handleLanguageChange('en')}>
                  EN
                </button>
              /
              <button  
              className="btn-language"
              onClick={() => handleLanguageChange('gr')}>
                GR
              </button>
            </li>
          </ul>
    </header>
  );
};

export default Header;