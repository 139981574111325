import React, { useState, useEffect } from "react";
import MenuComponent from "../components/MenuComponent"
import Header from "../components/Header"
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer"

export const Texts = () => {

    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const { t, i18n } = useTranslation();

    const languageClass = i18n.language === 'gr' ? 'greek-font' : '';    

    const handleCloseButton = () => {
      setIsMenuVisible(false);
    };

    const toggleMenu = () => {
      setIsMenuVisible(true);
    };

    useEffect(() => {

      const datelines1Element = document.getElementById("line");
      const datelines2Element = document.getElementById("line2");
      const datelines3Element = document.getElementById("line3");

      const growOptions = {
          threshold: 0.5,
      };
  
      const datelines1Observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
          if (entry.isIntersecting) {
              entry.target.style.animation = "growEventLines 2s forwards 0.5s";
          }
          });
      }, growOptions);
  
      const datelines2Observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
          if (entry.isIntersecting) {
              entry.target.style.animation = "growEventLines 2s forwards 0.5s";
              datelines2Observer.unobserve(entry.target);        
          }
          });
      }, growOptions);

      const datelines3Observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = "growEventLines 2s forwards 0.5s";
            datelines3Observer.unobserve(entry.target);        
        }
        });
    }, growOptions);
  
      datelines1Observer.observe(datelines1Element);
      datelines1Observer.observe(datelines2Element);
      datelines1Observer.observe(datelines3Element);
  
    }, []);

  return (
    <>
      <Header toggleMenu={toggleMenu}/>  
      <div className="future-events">
        <h1 className={`title-future-events ${languageClass}`}>{t("upcoming")} :</h1>
        <div id="line3"></div>
        <div className="container-future-events">
          <div className="container-events-infos">
            <div id="line"></div>
            <div className="event-infos">
              <h2 className={`event-dates ${languageClass}`}>{t("korcaDates")}</h2>
              <h2 className="event-name">The Days of P.Mia</h2>
              <h2 className="event-location">Korca International Art Colony</h2>
              <a href="https://www.facebook.com/qak.korce.3"
                target="_blank" 
                className= {`event-more-infos ${languageClass}`} 
                >{t("infosButton")}</a>
            </div>
          </div> 
          <div className="event-image-container">
            <img className="event-image" src="../images/upcoming events/korca_October_2023.jpg" alt="Description of the image"/>
          </div>
        </div>
        <div id="line2"></div>
        <Footer />
      </div>    
      {isMenuVisible && (
        < MenuComponent closeButtonClicked={handleCloseButton} /> 
      )}
    </>
  );
};

export default Texts